import axios from 'axios'

let url = window.location.host
if (url == "localhost:8086") url = "hongwei-ch.test123.tw"
const base = 'https://' + url + '/api'

const apiConfig = axios.create({
    baseURL: base
}) 
export default apiConfig
